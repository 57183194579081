<template>
  <div>
    <nav
      class="flex"
      :class="{ navbar: !isNavbarColorEnabled, 'navbar-colored': isNavbarColorEnabled }"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item-logo" href="/"></a>
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': isMenuActive }"
          aria-label="menu"
          aria-expanded="false"
          @click="updateMenuState(!isMenuActive)"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        class="navbar-menu"
        style="position: absolute; right: 0px; height: 100%"
      >
        <div class="navbar-start">
          <a @click="routerView('/about')" class="navbar-item">
            株式会社if について
          </a>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> 業務内容 </a>

            <div class="navbar-dropdown">
              <a class="navbar-item" @click="routerView('/public/house')">
                個人のお客様
              </a>
              <a
                class="navbar-item"
                @click="routerView('/public/unoccupied-house')"
              >
                空き家をお持ちのお客様
              </a>
              <a class="navbar-item" @click="routerView('/management-company')">
                不動産の管理会社様
              </a>
              <a class="navbar-item" @click="routerView('/owner')">
                賃貸物件のオーナー様
              </a>
            </div>
          </div>

          <a @click="routerView('/access')" class="navbar-item"> アクセス </a>

          <a @click="routerView('/contact')" class="navbar-item">
            お問い合わせ
          </a>
        </div>
      </div>
    </nav>

    <div
      class="side-menu-bar"
      :class="{ 'side-menu-bar-active': isMenuActive }"
    >
      <aside class="menu">
        <div style="padding: 1em">
          <img src="./../assets/logo4.svg" />
        </div>
        <ul class="menu-list">
          <li @click="routerView('/')"><a>ホーム</a></li>
          <li @click="routerView('/about')"><a>株式会社if について</a></li>
          <li @click="routerView('/access')"><a>交通アクセス</a></li>
          <li @click="routerView('/contact')"><a>お問い合わせ</a></li>
          <hr style="background-color: #f5f5f5; margin: 0.5rem 0" />
          <li @click="routerView('/public/house')"><a>個人のお客様</a></li>
          <li @click="routerView('/public/unoccupied-house')">
            <a>空き家をお持ちのお客様</a>
          </li>
          <li @click="routerView('/management-company')">
            <a>不動産の管理会社様</a>
          </li>
          <li @click="routerView('/owner')"><a>賃貸物件のオーナー様</a></li>
        </ul>
      </aside>
    </div>
    <div
      class="modal"
      :class="{ 'is-active': isMenuActive, 'is-clipped': isMenuActive }"
    >
      <div
        class="modal-background"
        @click="updateMenuState(!isMenuActive)"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  watch: {},
})
export default class NavigationHeader extends Vue {
  @SystemModule.Getter("isMenuActive")
  private isMenuActive!: boolean;

  @SystemModule.Action("updateMenuState")
  private updateMenuState!: (state: boolean) => Promise<void>;

  @SystemModule.Getter("isNavbarColored")
  private isNavbarColored!: boolean;

  private get isNavbarColorEnabled (): boolean {
    return !this.isHome || (this.isHome && this.isNavbarColored)
  }

  private contactModal = false;

  private async routerView(to: string) {
    await this.updateMenuState(false);
    this.$router.push(to);
  }

  private get isHome() {
    return this.$route.path === "/" || this.$route.path === "home";
  }
}
</script>
<style lang="scss">
// @import "~@/../node_modules/bulma/bulma.sass";
@import "@/assets/util.scss";

.navbar-burger {
  position: absolute !important;
  right: 0px;
}

.navbar {
  background-color: rgba(0, 0, 0, 0) !important;
  position: fixed !important;
  width: 100vw;
  z-index: 11 !important;

  .navbar-item {
    color: #2e3192;
    font-weight: 700;

    .navbar-link {
      color: #2e3192;
    }

    .navbar-dropdown {
      color: #2e3192;

      .navbar-item {
        color: #2e3192;
      }
    }
  }

  .navbar-burger {
    color: #2e3192;
  }

  .navbar-item-logo {
    background: url("./../assets/logo4.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 23px;
    height: 40px;
    margin: 7.5px 20px 0px 20px;
  }

  @include pc {
  }

  @include tablet {
  }

  @include sp {
  }
}

.navbar-colored {
  background-color: #2e3192;
  position: fixed;
  width: 100vw;
  z-index: 11;

  .navbar-item {
    color: white;
    font-weight: 700;

    .navbar-link {
      color: white;
    }

    .navbar-dropdown {
      color: white;

      .navbar-item {
        color: #2e3192;
      }
    }
  }

  .navbar-item:hover {
    color: #2e3192;

    .navbar-link {
      color: #2e3192;
    }
  }

  .navbar-dropdown:hover {
    color: white;

    .navbar-item {
      color: #2e3192;
    }
  }

  .navbar-burger {
    color: white;
  }

  .navbar-item-logo {
    padding: 10px;
    background: url("./../assets/logo_dark.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 23px;
    height: 40px;
    margin: 7.5px 20px 0px 20px;
  }

  @include pc {
  }

  @include tablet {
  }

  @include sp {
  }
}

.side-menu-bar {
  position: fixed;
  right: 0px;
  top: 0px;
  background-color: white;
  z-index: 41;

  width: 210px;
  padding: 10px;
  min-height: 100vh;

  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;

  -webkit-transform: translate3d(210px, 0px, 0px);
  transform: translate3d(210px, 0px, 0px);

  .menu-list a,
  p {
    color: #2e3192;
  }
}

.side-menu-bar-active {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.navbar-burger span {
  height: 1.5px !important;
}
</style>
