
import { Options, Vue } from "vue-class-component";
import NavigationHeader from "@/components/NavigationHeader.vue";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  props: {
    msg: String,
  },
  components: {
    NavigationHeader,
  },
})
export default class App extends Vue {
  private primaryStatus = false;

  private PrimaryClick(buttonStatus: boolean, refName: string) {
    this.primaryStatus = !buttonStatus;

    const el = this.$refs[refName] as any;
  }

  private async routerView(to: string) {
    this.$router.push(to);
    window.scrollTo(0, 0);
  }

  @SystemModule.Getter("isPageLoading")
  private isPageLoading!: boolean;

  @SystemModule.Getter("isMenuActive")
  private isMenuActive!: boolean;

  @Watch("isPageLoading")
  public async onChangePageLoading(): Promise<void> {
    this.switchModalState(this.isPageLoading);
  }

  @Watch("isMenuActive")
  public async onChangeMenuActive(): Promise<void> {
    this.switchModalState(this.isMenuActive);
  }

  private switchModalState(modalState: boolean) {
    if (modalState) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }
}
