<template>
  <div class="home">
    <div style="height: 100vh; width: 100vw">
      <div class="catch-copy-container"></div>
      <div class="catch-copy-modal">
        <div class="catch-copy-main">
          <img src="./../assets/catch_copy.svg" />
        </div>
        <div class="catch-copy-sub">
          <p>住まいの「もしも」、私たち if が解決します。</p>
        </div>
      </div>
    </div>
    <!-- <div class="corporate-info-sub" style="padding:6rem 1.5rem 6rem;">
      <div class="container is-fluid">
        <div style="text-align:center;font-size:2em;font-weight:600;padding:2rem 1.5rem 2rem;">アパート・マンションから戸建てまで、住まいの「もしも」、私たちが解決します。</div>
        <div class="columns">
          <div class="column">
            <ul>
              <li>□壁紙を貼り替えて模様替えしたい</li>
              <li>□畳をフローリングに替えたい</li>
              <li>□キッチンを取り替えたい</li>
              <li>□トイレを新しくしたい</li>
              <li>□網戸を張り替えてほしい</li>
              <li>□ドアの開閉をスムーズに直してほしい</li>
              <li>□照明を取り替えたい</li>
              <li>□遺品整理を任せたい</li>
              <li>□水漏れしてまったので急いで直してほしい</li>
              <li>□窓ガラスを割ってしまったので直してほしい</li>
            </ul>
          </div>
          <div class="column">
            <ul>
              <li>□はがれてしまったタイルを直してほしい</li>
              <li>□家中のエアコンをクリーニングしてほしい</li>
              <li>□外壁を塗装し直してキレイにしたい</li>
              <li>□駐車スペースを拡げて整備したい</li>
              <li>□庭にシンボルツリーと花壇がほしい</li>
              <li>□所有しているアパートの管理をお願いしたい</li>
              <li>□アパートの入居率をアップしたい</li>
              <li>□入居者の退去立会いを任せたい</li>
              <li>□実家が空き家になってしまいどうしたらいいか分からない</li>
              <li>□その他</li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div style="padding: 10rem 0rem 5rem" class="animate-tag">
      <div class="container is-fluid">
        <div class="columns">
          <div class="column is-half content-configured">
            <div>
              <p class="content-configured-title">
                住まいの「もしも」解決します
              </p>
              <p class="content-configured-subtitle">
                アパート・マンションから戸建てまで、住まいの「もしも」、私たちが解決します。豊富な専門知識と確かな技術を備えた「if」のスタッフが、お客様のご要望に丁寧にお応えいたします。
              </p>
              <a class="button is-primary" @click="routerView('/public/house')"
                >詳しく見る</a
              >
            </div>
          </div>
          <div class="column is-half">
            <img style="width:100%;" src="./../assets/kitchen_1m.jpeg" />
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 10rem 0rem 5rem" class="animate-tag">
      <div class="container is-fluid">
        <div class="columns">
          <div class="column is-half content-configured">
            <div>
              <p class="content-configured-title">
                空き家の「もしも」解決します
              </p>
              <p class="content-configured-subtitle">
                私たち「if」では、お話を伺い現地調査を行なった上で、建物とお客様の状況に合った最適な方策をご提案いたします。
              </p>
              <a
                class="button is-primary"
                @click="routerView('/public/unoccupied-house')"
                >詳しく見る</a
              >
            </div>
          </div>
          <div class="column is-half">
            <img style="width:100%;" src="./../assets/5034544_s.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 5rem 0rem 5rem" class="animate-tag">
      <div class="container is-fluid">
        <div class="columns">
          <div class="column is-half content-configured">
            <div>
              <p
                class="content-configured-title"
              >
                不動産管理の「もしも」解決します
              </p>
              <p class="content-configured-subtitle">
                リフォームはもちろん、クレーム対応や退去立会いまで、
                不動産管理のお手伝いをいたします。自社施工だから一括お任せでコストを低減。
                アフターケアもスピーディーに対応いたします。
              </p>
              <a
                class="button is-primary"
                @click="routerView('management-company')"
                >詳しく見る</a
              >
            </div>
          </div>
          <div class="column is-half">
            <img style="width:100%;" src="./../assets/YqcK9t.png" />
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 5rem 0rem 5rem" class="animate-tag">
      <div class="container is-fluid">
        <div class="columns">
          <div class="column is-half content-configured">
            <div>
              <p
                class="content-configured-title"
              >
                賃貸経営の「もしも」解決します
              </p>
              <p class="content-configured-subtitle">
                オーナーさまの利益を第一に、賃貸経営のお手伝いをいたします。魅力的なお部屋にすることで空室期間を短くし、オーナーさまの利益につなげます。
              </p>

              <a class="button is-primary" @click="routerView('owner')"
                >詳しく見る</a
              >
            </div>
          </div>
          <div class="column is-half">
            <img style="width:100%;" src="./../assets/4904866_m.jpg" />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="news-container" style="padding: 2rem 0rem 3rem">
      <div class="container is-fluid">
        <div
          style="font-size: 2em; text-align: center; padding: 0rem 0rem 3rem"
        >
          What's New
        </div>
        <div class="columns is-multiline">
          <div
            v-for="(newsItem, index) in newsList"
            :key="index"
            class="column is-full"
          >
            <div class="card">
              <!-- <div class="card-image">
                <figure class="image is-4by3">
                  <img
                    :src="newsItem.img"
                    alt="Placeholder image"
                  />
                </figure>
              </div> -->
              <div class="card-content" style="padding: 0rem">
                <div class="content">
                  <time datetime="2020-9-8">{{ newsItem.date }}</time>
                  <div>{{ newsItem.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <button class="button is-primary" @click="routerView('news')">
            <strong>What's New一覧</strong>
          </button>
        </div>
      </div>
    </div>
    <ToContactContent />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import { NewsItem } from "@/store/types";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent,
  },
})
export default class Home extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);

    document.addEventListener("scroll", () => {
      const animateTags = document.querySelectorAll(".animate-tag");
      const topContent = document.querySelector(".catch-copy-modal");

      if (topContent) {
        const topContentRect = topContent.getBoundingClientRect();
        this.updateNavbarColored(topContentRect.bottom < 0)
      }

      for (const animateTag of animateTags) {
        const rect = animateTag.getBoundingClientRect();

        if (rect.bottom < window.innerHeight + (rect.bottom - rect.top) / 2) {
          animateTag.classList.add("visible");
        } else {
          animateTag.classList.remove("visible");
        }
      }
    });

    await this.getNewsList();
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  @SystemModule.Action("getNewsList")
  private getNewsList!: () => Promise<void>;

  @SystemModule.Action("updateNavbarColored")
  private updateNavbarColored!: (state: boolean) => Promise<void>

  @SystemModule.Getter("newsList")
  private newsList!: NewsItem[];

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }
}
</script>
<style lang="scss">
@import "@/assets/util.scss";

.content-configured {

  display: flex;
  align-items: center;

  .content-configured-title {
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 5px;
    word-break: break-word;
  }

  .content-configured-subtitle {
    font-size: 20px;
    padding-bottom: 10px;
  }

  @include sp {
    .content-configured-title {
      font-size: 5.4vw;
      font-weight: 600;
      padding-bottom: 5px;
      word-break: break-word;
    }

    .content-configured-subtitle {
      font-size: 3.8vw;
      padding-bottom: 10px;
    }
  }
}

@keyframes zoom {
  0% {
    background-image: url("./../assets/YqcK9t.png");
  }
  40% {
    background-image: url("./../assets/house_2m.jpeg");
  }
  80% {
    background-image: url("./../assets/chintai_2m.jpeg");
  }
  100% {
    background-image: url("./../assets/YqcK9t.png");
  }
}

.animate-tag {
}

.animate-tag {
  opacity: 0;
  transform: translate(0, 10vh);
  transition: all 1s;
}

.animate-tag.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.home {
  .catch-copy-modal {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(230, 230, 230, 0.6);
    text-align: center;

    .catch-copy-main {
      width: 100vw;
    }

    .catch-copy-sub {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-weight: 500;
      color: #2e3192;
    }

    @include pc {
      .catch-copy-main {
        font-size: 72px;

        img {
          padding-top: 250px;
          width: 600px;
        }
      }

      .catch-copy-sub {
        font-size: 24px;
      }
    }

    @include tablet {
      .catch-copy-main {
        font-size: 64px;

        img {
          padding-top: 250px;
          width: 500px;
        }
      }

      .catch-copy-sub {
        font-size: 24px;
      }
    }

    @include sp {
      .catch-copy-main {
        font-size: 10vw;

        img {
          padding-top: 250px;
          width: 275px;
        }
      }

      .catch-copy-sub {
        font-size: 4vw;
      }
    }
  }

  .catch-copy-container:before {
    content: "";
    display: block;
    height: 100vh;
    width: 100vw;
    // background-image: url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)), url("./../assets/YqcK9t.png");
    -webkit-animation: zoom 10s 1;
    animation: zoom 25s 1;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    overflow: hidden;
  }

  .catch-copy-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    // background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)), url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;

    // .catch-copy-main {
    //   width: 100vw;
    // }

    // .catch-copy-sub {
    //   font-family: Avenir, Helvetica, Arial, sans-serif;
    //   font-weight: 500;
    //   color: #2e3192;
    // }

    // @include pc {
    //   .catch-copy-main {
    //     font-size: 72px;

    //     img {
    //       padding-top: 250px;
    //       width: 600px;
    //     }
    //   }

    //   .catch-copy-sub {
    //     font-size: 24px;
    //   }
    // }

    // @include tablet {
    //   .catch-copy-main {
    //     font-size: 64px;

    //     img {
    //       padding-top: 250px;
    //       width: 500px;
    //     }
    //   }

    //   .catch-copy-sub {
    //     font-size: 24px;
    //   }
    // }

    // @include sp {
    //   .catch-copy-main {
    //     font-size: 10vw;

    //     img {
    //       padding-top: 250px;
    //       width: 275px;
    //     }
    //   }

    //   .catch-copy-sub {
    //     font-size: 4vw;
    //   }
    // }
  }

  .corporate-info-sub {
    padding: 10px;

    p {
      color: #2e3192;
    }
  }

  .news-container {
    padding: 10px;

    .card {
      box-shadow: none;

      .card-image:first-child img {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
}
</style>
