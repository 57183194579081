import { Module } from "vuex";
import { RootState, SystemState } from "@/store/types";
import actions from "@/store/system/actions";
import getters from "@/store/system/getters";
import mutations from "@/store/system/mutations";

const state: SystemState = {
  isMenuActive: false,
  isPageLoading: false,
  newsList: [],
  isNavbarColored: false
};

export const system: Module<SystemState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
