import { GetterTree } from "vuex";
import { SystemState, RootState } from "@/store/types";

const getters: GetterTree<SystemState, RootState> = {
  isMenuActive: (state: SystemState) => {
    return state.isMenuActive;
  },
  isPageLoading: (state: SystemState) => {
    return state.isPageLoading;
  },
  newsList: (state: SystemState) => {
    return state.newsList;
  },
  isNavbarColored: (state: SystemState) => {
    return state.isNavbarColored;
  }
};

export default getters;
