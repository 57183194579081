<template>
  <div>
    <navigation-header></navigation-header>
    <template v-if="isPageLoading">
      <div
        style="
          width: 100vw;
          height: 100vh;
          background-color: #ffffff;
          position: fixed;
          z-index: 11;
        "
      >
        <div class="loader-spinners">Loading...</div>
      </div>
    </template>
    <router-view />
    <footer class="footer" style="text-align: left">
      <div class="content has-text-centered" style="text-align: left">
        <div class="container is-fluid">
          <div class="columns">
            <div class="column is-one-third" style="text-align: left">
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/about')"
              >
                株式会社if について
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/contact')"
              >
                お問い合わせ
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/news')"
              >
                ニュース
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/recruit')"
              >
                採用情報
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/access')"
              >
                交通アクセス
              </div>
            </div>
            <div class="column is-one-third" style="text-align: left">
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/public/house')"
              >
                個人のお客様
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/public/unoccupied-house')"
              >
                空き家をお持ちのお客様
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/management-company')"
              >
                不動産の管理会社様
              </div>
              <div
                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer"
                @click="routerView('/owner')"
              >
                賃貸物件のオーナー様
              </div>
            </div>
            <div class="column is-one-third" style="text-align: left">
              <div>
                <span class="icon" style="margin-right: 10px">
                  <i class="mdi mdi-24px mdi-facebook"></i>
                </span>
                <span class="icon" style="margin-right: 10px">
                  <i class="mdi mdi-24px mdi-instagram"></i>
                </span>
                <span class="icon" style="margin-right: 10px">
                  <i class="mdi mdi-24px mdi-twitter"></i>
                </span>
                <span class="icon" style="margin-right: 10px">
                  <i class="mdi mdi-24px mdi-youtube"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container is-fluid"
          style="text-align: left; padding-top: 3em"
        >
          <div>©︎ rehome if Inc.</div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NavigationHeader from "@/components/NavigationHeader.vue";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  props: {
    msg: String,
  },
  components: {
    NavigationHeader,
  },
})
export default class App extends Vue {
  private primaryStatus = false;

  private PrimaryClick(buttonStatus: boolean, refName: string) {
    this.primaryStatus = !buttonStatus;

    const el = this.$refs[refName] as any;
  }

  private async routerView(to: string) {
    this.$router.push(to);
    window.scrollTo(0, 0);
  }

  @SystemModule.Getter("isPageLoading")
  private isPageLoading!: boolean;

  @SystemModule.Getter("isMenuActive")
  private isMenuActive!: boolean;

  @Watch("isPageLoading")
  public async onChangePageLoading(): Promise<void> {
    this.switchModalState(this.isPageLoading);
  }

  @Watch("isMenuActive")
  public async onChangeMenuActive(): Promise<void> {
    this.switchModalState(this.isMenuActive);
  }

  private switchModalState(modalState: boolean) {
    if (modalState) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }
}
</script>

<style lang="scss">
@import "~@/../node_modules/bulma/bulma.sass";
body {
  overflow-x: hidden;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

// _::-webkit-full-page-media, _:future, :root .css-property{
//   width: 100vw;
// }

.loader-spinners,
.loader-spinners:before,
.loader-spinners:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader-spinners {
  color: #2e3192;
  font-size: 10px;
  margin: auto;
  position: relative;
  top: 40%;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader-spinners:before,
.loader-spinners:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader-spinners:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-spinners:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.footer {
  padding: 4.5rem 0rem 3rem;

  .icon & span {
  }
}
</style>
