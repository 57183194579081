
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import { NewsItem } from "@/store/types";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent,
  },
})
export default class Home extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);

    document.addEventListener("scroll", () => {
      const animateTags = document.querySelectorAll(".animate-tag");
      const topContent = document.querySelector(".catch-copy-modal");

      if (topContent) {
        const topContentRect = topContent.getBoundingClientRect();
        this.updateNavbarColored(topContentRect.bottom < 0)
      }

      for (const animateTag of animateTags) {
        const rect = animateTag.getBoundingClientRect();

        if (rect.bottom < window.innerHeight + (rect.bottom - rect.top) / 2) {
          animateTag.classList.add("visible");
        } else {
          animateTag.classList.remove("visible");
        }
      }
    });

    await this.getNewsList();
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  @SystemModule.Action("getNewsList")
  private getNewsList!: () => Promise<void>;

  @SystemModule.Action("updateNavbarColored")
  private updateNavbarColored!: (state: boolean) => Promise<void>

  @SystemModule.Getter("newsList")
  private newsList!: NewsItem[];

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }
}
