<template>
  <div class="to-contact-content">
    <div class="columns to-contact-content-item">
      <div
        class="column to-contact-content-item-details"
      >
        <div>相談は全て無料です。</div>
        <div>まずはお気軽にお問い合わせ下さい。</div>
        <div class="to-contact-content-item-link">
          <a class="button is-primary" @click="routerView('/contact')"
            >お問い合わせページへ</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({})
export default class ToContactContent extends Vue {
  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/util.scss";

.to-contact-content {
  text-align: left;
  padding: 30px;
  background-color: #f5f5f5;
  padding: 1rem 0rem 1rem;

  .to-contact-content-item {
    padding: 3rem 1.5rem 3rem;

    .to-contact-content-item-details {
      font-size: 1.7em;
      font-weight: 500;
      padding: 1rem 1.5rem 1rem;
    }

    .to-contact-content-item-link {
      padding-top: 1rem;
      // a {
      //   position: relative;
      //   top: calc(100% - 40px);
      // }
    }

    @include sp {
      .to-contact-content-item-details {
        font-size: 5vw;
        font-weight: 500;
        padding: 1rem;
      }
    }
  }
}
</style>
