import { MutationTree } from "vuex";
import { NewsItem, SystemState } from "@/store/types";

const mutations: MutationTree<SystemState> = {
  changeMenuState: (state, isMenuState: boolean) => {
    state.isMenuActive = isMenuState;
  },
  changePageLoadingState: (state, isPageLoading: boolean) => {
    state.isPageLoading = isPageLoading;
  },
  changeNewsList: (state, newsList: NewsItem[]) => {
    state.newsList = newsList;
  },
  changeNavbarColored: (state, isNavbarColored: boolean) => {
    state.isNavbarColored = isNavbarColored
  }
};

export default mutations;
