import { ActionTree } from "vuex";
import { SystemState, RootState, NewsItem } from "@/store/types";
import router from "@/router";

const actions: ActionTree<SystemState, RootState> = {
  updateMenuState: async ({ commit }, isMenuActice: boolean) => {
    commit("changeMenuState", isMenuActice);
  },
  updatePageLoadingState: async ({ commit }, isPageLoading: boolean) => {
    commit("changePageLoadingState", isPageLoading);
  },
  updateNavbarColored: async ({ commit }, isNavbarColored: boolean) => {
    commit("changeNavbarColored", isNavbarColored)
  },
  routerView: async ({ commit }, routerPath: string) => {
    router.push(routerPath);
  },
  getNewsList: async ({ commit, state }) => {
    try {
      if (state.newsList.length > 0) {
        return;
      }

      const list: NewsItem[] = [
        {
          title: "内装工事料金改定のお知らせ",
          date: "2021/09/02",
          img: "https://bulma.io/images/placeholders/1280x960.png",
          details: "この度、原材料の高騰により、2021年9月12日より以下の工事料金を改定させていただきます。\n\n対象：内装工事全般\n\n時期：2021年9月12日\n\n内容：内装工事基本料金を約5〜10％値上げ\n\n料金改定によりお客様にはご負担とご迷惑をおかけいたします分、より一層のサービス向上に努めて参る所存です。\n何卒、ご理解ご了承いただけますようお願い申し上げます。",
        },
        {
          title: "ホームページ開設のお知らせ",
          date: "2021/09/02",
          img: "https://bulma.io/images/placeholders/1280x960.png",
          details:
            "この度、株式会社ifのコーポレートサイトを開設いたしました。\n\n多くの皆様にご利用いただけるよう、内容の充実を図るとともに、わかりやすく・最新の情報を発信してまいりますので、どうぞ宜しくお願い申し上げます。",
        },
        {
          title: "事務所移転のお知らせ",
          date: "2020/12/8",
          img: "https://bulma.io/images/placeholders/1280x960.png",
          details:
            "事務所移転に伴い、住所が以下のように変更されましたので、お知らせ致します。\n\n変更前:\n長野県須坂市大字須坂164番地1 クラージュすざかB105\n\n変更後:\n長野県須坂市大字須坂馬場町1298−13\n\n今後とも、どうぞ宜しくお願い申し上げます。",
        }
      ];

      commit("changeNewsList", list);
    } catch (error) {
      console.error(error);
    }
  }
};

export default actions;
