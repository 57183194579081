
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  watch: {},
})
export default class NavigationHeader extends Vue {
  @SystemModule.Getter("isMenuActive")
  private isMenuActive!: boolean;

  @SystemModule.Action("updateMenuState")
  private updateMenuState!: (state: boolean) => Promise<void>;

  @SystemModule.Getter("isNavbarColored")
  private isNavbarColored!: boolean;

  private get isNavbarColorEnabled (): boolean {
    return !this.isHome || (this.isHome && this.isNavbarColored)
  }

  private contactModal = false;

  private async routerView(to: string) {
    await this.updateMenuState(false);
    this.$router.push(to);
  }

  private get isHome() {
    return this.$route.path === "/" || this.$route.path === "home";
  }
}
